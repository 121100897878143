import { Logo } from "../components/logo";
import { Footer } from "../components/footer";
import { Layout } from "antd";

const { Content } = Layout;

export const Main = () => {
  return (
    <>
      <Content style={{ margin: "24px 16px 0", overflow: "initial" }} className="main">
        <Logo nav={true} />
      </Content>
      <Footer />
    </>
  );
};
