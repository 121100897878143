import { Button } from "antd";
import { Link } from "react-router-dom";

type Props = {
  nav?: boolean;
};
export const Logo = (props: Props) => {
  const { nav } = props;
  return (
    <section className="logo-container">
      <div className="logo-bg">
        <Link to="/">
          <div className="logo">
            <span data-text="Fitness"></span>
            <span className="logo-sign" data-text="by Dariia Stavnycha">
              by Dariia Stavnycha
            </span>
            <span data-text="school"></span>
          </div>
        </Link>
        <br></br>
        <br></br>
        <br></br>
        {nav && (
          <Link to="/plans">
            <Button>Підбери собі програму</Button>
          </Link>
        )}
      </div>
    </section>
  );
};
