import React, { useEffect } from "react";
import { Button, Flex, Row, Col } from "antd";
import gsap from "gsap";

import { InstagramOutlined, YoutubeOutlined } from "@ant-design/icons";
import logoTg from "../img/logoTg.png";
import logoTt from "../img/logoTt.jpg";

const telegramStyle: React.CSSProperties = {
  background: "linear-gradient(to top right, #3390ec 60%,#77afee 100%)",
};

const instagramStyle: React.CSSProperties = {
  background: "linear-gradient(to right, #ff3019 0%,#c90477 100%)",
};

const youtubeStyle: React.CSSProperties = {
  background: "linear-gradient(to top right, #F00 60%,#F77 100%)",
};

const tiktokStyle: React.CSSProperties = {
  background: "linear-gradient(to top right, #СССтзь 60%,#666 100%)",
};

export const Footer = () => {
  useEffect(() => {
    const animation = {
      duration: 1,
      ease: "power1.out",
      y: "0",
      opacity: 1,
      repeat: 0,
    };
    gsap.to(".footer-column.btn1", { ...animation, delay: 0.2 });
    gsap.to(".footer-column.btn2", { ...animation, delay: 0.4 });
    gsap.to(".footer-column.btn3", { ...animation, delay: 0.5 });
    gsap.to(".footer-column.btn4", { ...animation, delay: 0.8 });

    gsap.set(".txt-1", { x: "-100%", opacity: 0 });
    gsap.set(".txt-2", { x: "100%", opacity: 0 });
    gsap.to(".txt-1", { duration: 1, x: 0, opacity: 1 });
    gsap.to(".txt-2", { duration: 1, x: 0, opacity: 1, delay: 0.5 });
  }, []);

  return (
    <Flex className="footer" vertical justify={"center"}>
      <Row>
        <Col className="footer-column btn1">
          <Button
            type="primary"
            style={telegramStyle}
            icon={<img src={logoTg} alt="Telegram" style={{ height: "1em" }} />}
            href="https://t.me/stavnichadasha"  
            target="_blank"
          >
            Telegram
          </Button>
        </Col>
        <Col className="footer-column btn2">
          <Button
            type="primary"
            style={instagramStyle}
            icon={<InstagramOutlined alt="Instagram" />}
            href="https://www.instagram.com/stavnycha_dariia"
            target="_blank"
          >
            Instagram
          </Button>
        </Col>
        <Col className="footer-column btn3">
          <Button
            type="primary"
            style={youtubeStyle}
            icon={<YoutubeOutlined alt="YouTube" />}
          >
            YouTube
          </Button>
        </Col>
        <Col className="footer-column btn4">
          <Button
            type="primary"
            style={tiktokStyle}
            icon={<img src={logoTt} alt="TikTok" style={{ height: "1em" }} />}
          >
            TikTok
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};
