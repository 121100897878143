import { useEffect } from "react";
import { Avatar, Button, Card, Col, Row } from "antd";
import { Badge, Layout } from "antd";
import gsap from "gsap";
import { Logo } from "../components/logo";
import { Footer } from "../components/footer";


import i1 from "../img/plans/i1.jpg";
import i2 from "../img/plans/i2.jpg";
import i3 from "../img/plans/i3.jpg";
import i4 from "../img/plans/i4.jpg";
import i5 from "../img/plans/i5.jpg";
import i6 from "../img/plans/i6.jpg";
import i7 from "../img/plans/i7.jpg";
import { Link } from "react-router-dom";

const { Meta } = Card;
const { Content } = Layout;

const orderLink = "https://t.me/stavnichadasha";
const orderStyle: React.CSSProperties = {
  background: "linear-gradient(to top right, #F00 60%,#F77 100%)",
};
const orderAction = <Button type="primary" style={orderStyle}>Замовити</Button>;

export const Plans = () => {
  useEffect(() => {
    const animation = {
      duration: 1,
      ease: "power1.out",
      y: "0",
      opacity: 1,
      repeat: 0,
    };
    gsap.to(".plan-column.i1", { ...animation, delay: 0.1 });
    gsap.to(".plan-column.i2", { ...animation, delay: 0.2 });
    gsap.to(".plan-column.i3", { ...animation, delay: 0.3 });
    gsap.to(".plan-column.i4", { ...animation, delay: 0.4 });
    gsap.to(".plan-column.i5", { ...animation, delay: 0.5 });
    gsap.to(".plan-column.i6", { ...animation, delay: 0.6 });
    gsap.to(".plan-column.i7", { ...animation, delay: 0.7 });
  }, []);
  return (
    <>
      <Content style={{ margin: "24px 32px 0", overflow: "initial" }}>
        <Logo />

        <Row gutter={32}>
          <Col className="plan-column i1">
            <Badge.Ribbon text={<h4>150$</h4>} color="red">
              <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i1} />}
                  title="Терапія цвяхостояння "
                />
                <br></br>
                <hr></hr>
                Включає: створення відповідної атмосфери, підготовку до
                практики, чаювання, медитацію, натальні та тибетні пахощі, Пало
                Санто.
              </Card>
            </Badge.Ribbon>
          </Col>

          <Col className="plan-column i2">
            <Badge.Ribbon text={<h4>80$</h4>} color="red">
              <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i2} />}
                  title="План тренувань"
                  
                />
                <br></br>
                <hr></hr>
                Прописання плану під ваші цілі: вдома або в залі. Тривалістю у
                місяць.
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col className="plan-column i3">
            <Badge.Ribbon text={<h4>150$</h4>} color="red">
              <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i3} />}
                  title="План харчування"
                />
                <br></br>
                <hr></hr>
                План з прорахуванням КБЖУ, відштовхуючись від ваших цілей:
                cхуднення, набір маси, веганське меню, Кето-дієта тощо.
                Тривалістю у місяць.
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col className="plan-column i4">
            <Badge.Ribbon text={<h4>120$</h4>} color="red">
            <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i4} />}
                  title="Програма відео-тренувань"
                />
                <br></br>
                <hr></hr>
                Формат «включай та повторюй за мною». Програма тривалістю у
                місяць: 12+ відео-тренувань по 40-50хв.
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col className="plan-column i5">
            <Badge.Ribbon text={<h4>420$</h4>} color="red">
            <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i5} />}
                  title="Індивідуальна програма"
                />
                <br></br>
                <hr></hr>
                Особистий онлайн-тренер, який: відповість на всі запитання і
                доведе до результату. Включає: зідзвон і обговорення всіх цілей,
                підготовку та аналіз раціону, збір потрібної інформації,
                складення плану харчування + тренувань зал або дім, підтримку з
                понеділка по суботу з 10:00 по 19:00.
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col className="plan-column i6">
            <Badge.Ribbon text={<h4>80$</h4>} color="red">
            <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i6} />}
                  title="Офлайн тренування"
                />
                <br></br>
                <hr></hr>Офлайн тренування зі мною. При купівлі 5-ти тренувань
                +1 в подарунок.
              </Card>
            </Badge.Ribbon>
          </Col>
          <Col className="plan-column i7">
            <Badge.Ribbon text={<h4>30$</h4>} color="red">
            <Card actions={[orderAction]}>
                <Meta
                  avatar={<Avatar size={64} src={i7} />}
                  title="Групове офлайн тренування"
                />
                <br></br>
                <hr></hr>Групове офлайн тренування зі мною. При купівлі 7-ми
                тренувань +1 в подарунок.
              </Card>
            </Badge.Ribbon>
          </Col>
        </Row>
      </Content>
      <Footer />
    </>
  );
};
